<template>

    <div class="white-panel-items">

        <div class="wp-row" v-if="showCollection">
            <div class="flex-grids flex-item-middle">
                <div class="filter-title">{{ $t(dataSource.Filter.Title) }}:</div>
                <div class="flex-item-expand status-panel">
                    <v-chip-group active-class="active-panel-item" v-model="inputStatus" mandatory column>

                        <v-chip 
                            v-for="item in dataSource.Filter.Items"
                            :key="item.Name"
                            :value="item.Value"
                            class="ma-1"
                            small
                            label
                        >
                            {{ $t(item.Name) }}
                            <span v-if="moreOrEqualZero(item.Count)"> {{item.Count}} </span>
                        </v-chip>

                    </v-chip-group>
                </div>
            </div>
        </div>

        <div v-if="hasItems && !!subFilter" class="wp-row">
            <div class="flex-grids flex-item-middle">
                <div class="filter-title">{{ $t(subFilter.Title) }}:</div>
                <div class="flex-item-expand status-panel">
                    <v-chip-group 
                        v-model="inputSubstatus"
                        mandatory
                        column
                    >
                        <v-chip
                            v-for="item in subFilter.Items"
                            :key="item.Name"
                            :value="item.Value"
                            :class="[item.Value === inputSubstatus ? $options.filterValueClasses[inputSubstatus] ? $options.filterValueClasses[inputSubstatus] : 'active-panel-item' : '', 'ma-1']"
                            small
                            label
                        >
                            {{ $t(item.Name) }}
                            <span v-if="moreOrEqualZero(item.Count)"> {{item.Count}} </span>
                        </v-chip>

                    </v-chip-group>
                </div>
            </div>
        </div>

        <slot>
           
        </slot>

    </div>

</template>

<script>
export default {
    name: "FilterPanel",
    filterValueClasses: {
        Today: 'today',
        Expired: 'expired',
        LessThan10Days: 'soon',
        MoreThan10Days: 'long-while',
        '30': 'today',
        '40': 'expired',
        ListToday: 'today',
        CalendarToday: 'today'
    },
    props: {
        status: {
            type: String,
            default: null
        },
        substatus: {
            type: String,
            default: null
        },
        dataSource: {
            type: Object,
            default: null
        },
        showCollection: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
        }
    },
    computed: {
        hasItems () {
            let selected = this.dataSource.Filter.Items.find(x => x.Value == this.inputStatus);
            return selected != null ? selected.HasItems : false;
        },
        subFilter () {
            return this.dataSource.Filter.Items.find(x => x.Value == this.inputStatus).Items;
        },
        inputStatus:
        {
            get() {
                return this.status;
            },
            set(val) {
                this.$emit('update:status', val);
                let selected = this.dataSource.Filter.Items.find(x => x.Value == val);

                let hasItems = selected?.HasItems ?? false;

                if (hasItems === false) {
                    this.$emit('update:substatus', selected?.Items?.Default);
                }
            }
        },
        inputSubstatus: {
            get() {
                return this.substatus;
            },
            set(val) {
                this.$emit('update:substatus', val);
            }
        }
    },
    methods: {
        moreOrEqualZero (number) {
            if (typeof number !== 'number') return false;
            return number >= 0;
        }
    }
}
</script>

<style scoped>
    .today {
        background: #f57731;
        color: #fff!important;
    }
    .expired {
        background: #f04747;
        color: #fff!important;
    }
    .soon {
        background: #FAA61A;
        color: #fff!important;
    }
    .long-while {
        background: #43B581;
        color: #fff!important;
    }
</style>